import CommonApi from "@/api/common";
import Storage from "@/utils/storage";
import { MD5 } from "crypto-js";
import BaseConfig from "@/utils/config";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import moment from "moment";
import Device from "@/api/device";
const commonApi = new CommonApi();
const device = new Device();
// 防抖
export function debounce(func, wait = 0) {
  if (typeof func !== "function") {
    throw new TypeError("need a function arguments");
  }
  let timeid = null;
  let result;

  return function () {
    let context = this;
    let args = arguments;

    if (timeid) {
      clearTimeout(timeid);
    }
    timeid = setTimeout(function () {
      result = func.apply(context, args);
    }, wait);
    return result;
  };
}

//去重
export function unique(arr) {
  return Array.from(new Set(arr));
}

//过滤空值

export function fliterNullValue(params) {
  for (const i in params) {
    if (
      params[i] === null ||
      params[i] === "" ||
      params[i] === undefined ||
      params[i] === 0
    ) {
      delete params[i];
    }
  }
  return params;
}

//过滤数组空值
export function removeEmptyArrayEle(arr) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === undefined) {
      arr.splice(i, 1);
      i = i - 1; // i - 1 ,由于空元素在数组下标 2 位置，删除空以后，后面的元素要向前补位，
      // 这样才能真正去掉空元素,以为这句能够删掉的连续为空试试，而后思考其中逻辑
    }
  }
  return arr;
}

//重复性校验
//校验
export function handleCheckRepeat(
  value,
  params,
  key,
  Scene,
  ExistedMsg,
  EmptyMsg,
  cb
) {
  try {
    if (JSON.stringify(params) === "{}") {
      //新增
      if (value !== "" && typeof value !== "undefined") {
        commonApi
          .checkRepeat({ Scene: Scene, Kw: filterChineseSemicolon(value) })
          .then((res) => {
            if (res.Code === 0) {
              if (res.Existed) {
                cb(ExistedMsg);
              } else {
                cb();
              }
            }
          });
      } else {
        cb(EmptyMsg);
      }
    } else {
      let oldValue;
      if (key.length > 1) {
        oldValue = formateParams(params, key);
      } else {
        oldValue = params[key[0]];
      }

      //编辑
      if (value !== "") {
        if (value !== oldValue) {
          commonApi
            .checkRepeat({ Scene: Scene, Kw: filterChineseSemicolon(value) })
            .then((res) => {
              if (res.Code === 0) {
                if (res.Existed) {
                  cb(ExistedMsg);
                } else {
                  cb();
                }
              }
            });
        } else {
          cb();
        }
      } else {
        cb(EmptyMsg);
      }
    }
  } catch (err) {
    cb(err);
  }
}

export function CheckExistForLampModel(
  value,
  params,
  key,
  ExistedMsg,
  EmptyMsg,
  cb
) {
  try {
    if (JSON.stringify(params) === "{}") {
      //新增
      if (value !== "" && typeof value !== "undefined") {
        device
          .CheckExistForLampModel({ Name: filterChineseSemicolon(value) })
          .then((res) => {
            if (res.code === 0) {
              if (res.data.exist !== 0) {
                cb(ExistedMsg);
              } else {
                cb();
              }
            }
          });
      } else {
        cb(EmptyMsg);
      }
    } else {
      let oldValue;
      if (key.length > 1) {
        oldValue = formateParams(params, key);
      } else {
        oldValue = params[key[0]];
      }

      //编辑
      if (value !== "") {
        if (value !== oldValue) {
          device
            .CheckExistForLampModel({ Name: filterChineseSemicolon(value) })
            .then((res) => {
              if (res.code === 0) {
                if (res.data.exist !== 0) {
                  cb(ExistedMsg);
                } else {
                  cb();
                }
              }
            });
        } else {
          cb();
        }
      } else {
        cb(EmptyMsg);
      }
    }
  } catch (err) {
    cb(err);
  }
}

export function formateParams(obj, key) {
  let newobj = obj[key[0]];
  key.splice(0, 1);
  if (key.length === 0) {
    return newobj;
  } else {
    return formateParams(newobj, key);
  }
}

//检查权限
export function checkPermission(key, type) {
  let permission = Storage.load("permission");
  if (key === 0) {
    switch (type) {
      case "router":
        return true;
      case "control":
        return { display: "block" };
      case "control-inline":
        return { display: "inline-block" };
      default:
        return false;
    }
  }
  if (permission && permission.length > 0) {
    if (!key) {
      switch (type) {
        case "router":
          return false;
        case "control":
          return { display: "block" };
        case "control-inline":
          return { display: "none" };
        default:
          return false;
      }
    }
    if (permission.indexOf(key) !== -1) {
      switch (type) {
        case "router":
          return true;
        case "control":
          return { display: "block" };
        case "control-inline":
          return { display: "inline-block" };
        default:
          return false;
      }
    } else {
      switch (type) {
        case "router":
          return false;
        case "control":
          return { display: "none" };
        case "control-inline":
          return { display: "none" };
        default:
          return false;
      }
    }
  } else {
    switch (type) {
      case "router":
        return false;
      case "control":
        return { display: "none" };
      case "control-inline":
        return { display: "none" };
      default:
        return false;
    }
  }
}

//多语言映射
export function langSwitch(key) {
  console.log(key, 204, "langswitch");
  switch (key) {
    case 1:
      return "zh_cn";
    case 2:
      return "en";
    default:
      return "zh_cn";
  }
}

//md5加密
export function Crypto(type, str) {
  let result;
  switch (type) {
    case "md5":
      result = MD5(str).toString();
      break;
    case "base64":
      result = Base64.stringify(Utf8.parse(str)).toString();
      break;
    default:
      result = str;
      break;
  }

  return result;
}

//类型检查 只包含数字
export function CheckNumber(value) {
  if (value.length > 0) {
    value = value.replace(/[^\d]/g, "");
    return Number(value);
  } else {
    return "";
  }
}

//类型检查转换 包含浮点数
export function CheckFloatNumber(input) {
  const regex = /^-?(\d+|\d+\.\d+|\d*\.)?$|^-$/;
  let filteredInput = "";
  let negativeCount = 0;
  let decimalCount = 0;
  for (let i = 0; i < input.length; i++) {
    const char = input.charAt(i);
    if (char === "-") {
      negativeCount++;
      if (negativeCount > 1 || i > 0 || decimalCount > 0) {
        continue;
      }
    } else if (char === ".") {
      decimalCount++;
      if (decimalCount > 1 || i === 0) {
        continue;
      }
    } else if (isNaN(char)) {
      continue;
    }
    if (regex.test(char)) {
      filteredInput += char;
    }
  }
  if (
    filteredInput === "-" ||
    filteredInput === "-." ||
    filteredInput === "."
  ) {
    return "-";
  } else if (regex.test(filteredInput)) {
    return limitDecimalPlaces(filteredInput, 6);
  } else {
    return filteredInput;
  }
}

export function formatParams(key, value, params) {
  let newParams = { ...params };
  if (value) {
    newParams[key] = value;
  } else {
    delete newParams[key];
  }
  return newParams;
}

//格式化省市区
export function formatArea(areaData) {
  let arr = [];
  let ProvinceKey = Object.keys(areaData);
  ProvinceKey.forEach((key) => {
    let obj = {};
    obj.label = key;
    obj.value = key;
    obj.children = [];
    if (areaData[key]?.length > 0) {
      areaData[key].forEach((subkey) => {
        let subObj = {};
        subObj.label = subkey;
        subObj.value = subkey;
        obj.children.push(subObj);
      });
    }

    arr.push(obj);
  });
  return arr;
}

export function isUtf8(bytes) {
  var i = 0;
  while (i < bytes.length) {
    if (
      // ASCII
      bytes[i] == 0x09 ||
      bytes[i] == 0x0a ||
      bytes[i] == 0x0d ||
      (0x20 <= bytes[i] && bytes[i] <= 0x7e)
    ) {
      i += 1;
      continue;
    }

    if (
      // non-overlong 2-byte
      0xc2 <= bytes[i] &&
      bytes[i] <= 0xdf &&
      0x80 <= bytes[i + 1] &&
      bytes[i + 1] <= 0xbf
    ) {
      i += 2;
      continue;
    }

    if (
      // excluding overlongs
      (bytes[i] == 0xe0 &&
        0xa0 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf) || // straight 3-byte
      (((0xe1 <= bytes[i] && bytes[i] <= 0xec) ||
        bytes[i] == 0xee ||
        bytes[i] == 0xef) &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf) || // excluding surrogates
      (bytes[i] == 0xed &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0x9f &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf)
    ) {
      i += 3;
      continue;
    }

    if (
      // planes 1-3
      (bytes[i] == 0xf0 &&
        0x90 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf &&
        0x80 <= bytes[i + 3] &&
        bytes[i + 3] <= 0xbf) || // planes 4-15
      (0xf1 <= bytes[i] &&
        bytes[i] <= 0xf3 &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0xbf &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf &&
        0x80 <= bytes[i + 3] &&
        bytes[i + 3] <= 0xbf) || // plane 16
      (bytes[i] == 0xf4 &&
        0x80 <= bytes[i + 1] &&
        bytes[i + 1] <= 0x8f &&
        0x80 <= bytes[i + 2] &&
        bytes[i + 2] <= 0xbf &&
        0x80 <= bytes[i + 3] &&
        bytes[i + 3] <= 0xbf)
    ) {
      i += 4;
      continue;
    }

    return false;
  }

  return true;
}

//下载图片
export function downloadFile(filePath, name, isFull = false) {
  let url;
  if (isFull) {
    url = filePath;
  } else {
    url = BaseConfig.FileDomain + filePath;
  }
  let suffixName = name + filePath.substring(filePath.lastIndexOf("."));
  let a = document.createElement("a");
  a.download = suffixName;
  a.target = "blank";
  a.href = url;
  a.click();
}

//渲染图片路径
export function renderImageSrc(filePath, isFull = false) {
  if (isFull) {
    return filePath;
  } else {
    return `${BaseConfig.FileDomain}${filePath}`;
  }
}

//判断是否为图片
export function isAssetTypeAnImage(fileName) {
  let suffix = `(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|wmf|webp|jpeg)`;
  let regular = new RegExp(`[^\\/]+\\.(${suffix})$`, "i"); // 匹配路径中的最后一个文件名部分
  return regular.test(fileName);
}

//ping
export function ping(ip) {
  var img = new Image();
  var start = new Date().getTime();
  var flag = false;
  var tag;
  img.src = "http://" + ip + "/" + start;
  img.onload = function () {
    img.src = "http://" + ip + "/" + start;
    const nowTime = new Date().getTime();
    const diffTime = Math.abs(moment(nowTime).diff(start, "milliseconds"));
    if (diffTime < 10000) {
      if (flag) {
        flag = true;
        alert(`Ping time was ${diffTime} ms`);
        return;
      }
    } else {
      if (flag) {
        alert("Destination Host Unreachable");
        return;
      }
    }
  };

  img.onerror = function (e) {
    e.stopImmediatePropagation();
    flag = true;
    img.src = "http://" + ip + "/" + start;
    const nowTime = new Date().getTime();
    const diffTime = Math.abs(moment(nowTime).diff(start, "milliseconds"));
    img.onerror = null;
    if (diffTime > 10000) {
      clearTimeout(tag);
      alert("Destination Host Unreachable");
      return;
    } else {
      alert(`Ping time was ${diffTime} ms`);
      return;
    }
  };

  setTimeout(function () {
    console.log("network is working, start ping...");
  }, 1);
}

//逆转译地址
export function GeoPoint(lng, lat, type = "Baidu") {
  console.log(Storage.load("apiType"), type);
  const apiType = Storage.load("apiType") || type;
  console.log(apiType);
  switch (apiType) {
    case "Baidu":
      console.log(window.BMapGL, 544);
      if (window.BMapGL) {
        let pt = new window.BMapGL.Point(lng || 113.88308, lat || 22.55329);
        let myGeo = new window.BMapGL.Geocoder();
        myGeo.getLocation(pt, (rs) => {
          if (rs) {
            let { addressComponents } = rs;
            let address = `${addressComponents.province}${addressComponents.city}${addressComponents.district}${addressComponents.street}${addressComponents.streetNumber}`;
            Storage.save("address", address);
          } else {
            Storage.save("address", "-");
            return "-";
          }
        });
      } else {
        console.error("no load baiduMap");
      }

      break;
    case "Google":
      console.log("google", window.google);
      function initGoogleGeocoder() {
        console.log("调用函数initGoogleGeocoder");
        if (window.google && window.google.maps) {
          var myGeo1 = new window.google.maps.Geocoder();
          console.log(lat, lng);
          let ptg = new window.google.maps.LatLng(
            Number(lat) || 22.55329,
            Number(lng) || 113.88308
          );
          myGeo1.geocode({ location: ptg }, (result) => {
            if (result[0].formatted_address) {
              Storage.save("address", result[0].formatted_address);
            } else {
              Storage.save("address", "-");
            }
          });
        } else {
          Storage.save("address", "-");
          console.warn("Google Maps API 未初始化，等待加载...");
          setTimeout(initGoogleGeocoder, 1000);
        }
      }
      initGoogleGeocoder();
      break;
    default:
      break;
  }
}

//个位补零
export function fmtSingleNumber(num) {
  if (num < 10) {
    return "0" + num;
  } else {
    return num;
  }
}

function getYesterdayTimestamp() {
  const now = new Date();
  now.setDate(now.getDate() - 1);
  now.setHours(23, 59, 59, 0);
  return now.getTime();
}

export function TimeRange(days) {
  let endTime = getYesterdayTimestamp() / 1000;
  let startTime;
  startTime = moment(
    moment(moment().subtract(days, "days")).format("YYYY-MM-DD") + " 00:00:00"
  ).unix();
  return [startTime, endTime];
}

//获取今日零时时间戳
export function getTodayTimestamp() {
  // 获取当前日期对象
  var today = new Date();
  // 将当前日期设置为今天的00:00:00
  today.setHours(0, 0, 0, 0);
  // 获取今天00:00:00的时间戳（单位：毫秒）
  var timestamp = today.getTime();
  return timestamp;
}

//小数点位数限制
export function limitDecimalPlaces(number, decimalPlaces) {
  // 将 number 转换成字符串
  let str = number.toString();
  // 如果小数点后没有数字，则直接返回
  if (str.indexOf(".") === -1) {
    return number;
  }
  // 获取小数点后的位数
  let numDecimalPlaces = str.length - str.indexOf(".") - 1;
  // 如果小数点后的位数小于等于限制的位数，则直接返回
  if (numDecimalPlaces <= decimalPlaces) {
    return number;
  }
  // 否则，截取小数点前 decimalPlaces + 1 位，保留 decimalPlaces 位小数
  return parseFloat(str.substring(0, str.indexOf(".") + decimalPlaces + 1));
}

function filterChineseSemicolon(text) {
  /**
   * 过滤掉中文输入法中产生的分号
   *
   * @param {string} text 待处理的文本字符串
   *
   * @returns {string} 处理后的文本字符串，不包含中文输入法产生的分号
   */

  // 中文输入法中的分号编码为0x003B或0xFF1B，将其替换为空字符串
  const filteredText = text.replace(/[\u0027\uFF07\u2018\u2019]/g, "");
  return filteredText;
}

export function convertEmptyToUndefined(obj) {
  /**
   * 将对象内的属性值为空的转为undefined
   *
   * @param {object} obj 待处理的对象
   *
   * @returns {object} 处理后的对象，属性值为空的已转为undefined
   */

  // 遍历对象的所有属性
  for (let key in obj) {
    // 如果属性值是一个对象，递归调用该函数处理该对象的属性
    if (typeof obj[key] === "object" && obj[key] !== null) {
      obj[key] = convertEmptyToUndefined(obj[key]);
    }
    // 如果属性值为空，则将其转为undefined
    if (obj[key] === "") {
      obj[key] = undefined;
    }
  }
  return obj;
}

export function fmtLang(id) {
  let lang = "zh_cn";
  switch (id) {
    case 1:
      lang = "zh_cn";
      break;
    case 2:
      lang = "en";
      break;
    default:
      lang = "zh_cn";
      break;
  }
  return lang;
}
